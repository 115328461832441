<script lang="tsx" setup>
import { AuthService } from './logic'
import LoginEmail from './LoginEmail.vue'

export interface Props extends AuthService.Params {
  wrapperClass?: string

  /** Hides heading content */
  minimal?: boolean
}

const props = defineProps<Props>()
const auth = new AuthService(props)
</script>

<template>
  <div class="h-max w-full max-w-360px" :class="wrapperClass">
    <!-- Basic Info -->
    <div v-if="!minimal" class="mb-12 mt-6">
      <h1 class="mb-3 text-display-1 text-primary-500">
        Welcome to Vocal
      </h1>
      <p class="text-subheading-1 text-neutral-400">
        Connect your account and start sending voice messages
      </p>
    </div>

    <!-- CTA Buttons  -->
    <div class="grid gap-2.5 place-items-stretch">
      <!-- Hinted Account Login -->
      <CTAButton v-if="hint" @resolve-click="auth.login('google')">
        <span text-sm>Continue as {{ hint }}</span>
      </CTAButton>

      <CTAButton variant="secondary" :class="$style.login_cta" @resolve-click="auth.login('google')">
        <i-logos-google-icon />
        Continue with Google
      </CTAButton>

      <CTAButton variant="secondary" :class="$style.login_cta" @resolve-click="auth.login('microsoft')">
        <i-logos-microsoft-icon />
        Continue with Microsoft
      </CTAButton>
    </div>

    <div :class="$style.divider">
      or
    </div>

    <LoginEmail />
  </div>
</template>

<style module lang="sass">
.login_cta
  background-color: transparent !important
  border-color: theme('colors.neutral.200') !important

.divider
  @apply my-6 flex-center gap-3 text-sm

  &:before, &:after
    @apply inline-block content-[''] w-full h-1px bg-neutral-200
</style>
