<script lang="ts" setup>
import { useAPIHandle } from 'core'
import { AuthService } from './logic'

const props = defineProps<{ hint?: string }>()

const otp = ref<string[]>([])
const otpLength = 5

const email = useLocalStorage('login-otp-email', props.hint || '', {
  mergeDefaults: (email, defaults) => email || defaults
})

const confirmOTP = useLocalStorage('login-otp-confirm', false)

const [requestState, requestLogin] = useAPIHandle(async () => {
  const result = await api.auth.login('email', { email: email.value })
  confirmOTP.value = result.success

  return result
})

const [verifyState, verifyOTP] = useAPIHandle(async () => {
  const key = otp.value.join('')

  if (key.length < otpLength)
    return

  const result = await api.auth.login('email', { email: email.value, key })

  // Reset
  if (result.success) {
    email.value = ''
    confirmOTP.value = false
  }

  // Wait for data setting
  nextTick(() => {
    AuthService.loginWithResult(result)
  })

  return result
})
</script>

<template>
  <form @submit.prevent="requestLogin">
    <input v-model.trim="email" required type="email" placeholder="Your email address" class="mb-3">
    <CTAButton class="w-full" type="submit" :disabled="!email.includes('@')" :loading="verifyState.loading || requestState.loading">
      Continue with email
    </CTAButton>

    <!-- Confirmation Dialog -->
    <Dialog v-model="confirmOTP">
      <DialogTitle class="text-lg text-neutral-600 font-medium">
        Enter OTP code
      </DialogTitle>

      <DialogDescription class="text-neutral-500">
        We have sent a code to <b>{{ email }}</b> as confirmation.
        Please copy and paste it here to verify.
      </DialogDescription>

      <!-- Message -->
      <div v-if="verifyState.error" class="p-4 bg-red-400 text-sm text-white rounded">
        Confirmation failed. Make sure you have entered the correct code.
      </div>

      <LinearProgress v-if="verifyState.loading" height="6px" class="w-250px mx-auto" infinite />

      <PinInputRoot
        v-else
        id="pin-input"
        v-model="otp"
        placeholder="○"
        class="mt-4 mx-auto flex-center border rounded"
        @complete="verifyOTP"
      >
        <PinInputInput
          v-for="(id, index) in otpLength"
          :key="id"
          :index="index"
          class="p-0 size-10 rounded-none border-none not-last:border-r-1 not-last:border-r-solid not-last:border-r-neutral-200 text-center text-neutral-600 placeholder:text-neutral-400 focus:rounded focus:outline-2 focus:outline-offset-0 focus:outline-primary-500"
        />
      </PinInputRoot>
    </Dialog>
  </form>
</template>
