import type { Auth } from 'core'
import { openPopup } from 'core'
import { type AuthProvider, AuthSession } from './session'

export class AuthService {
  // States
  state = shallowReactive({
    isProcessing: false,
    isAuthorized: false
  })

  constructor(public params: AuthService.Params = {}) { }

  static loginWithResult(result: Auth.LoginResult) {
    if (!result.success)
      throw new Error('Authorization failed')
    if (!result.access_token)
      throw new Error('access_token in missing in provided Auth.LoginResult')

    Logger.debug(`🔓 Authorization successful (${result.email})`)
    return http.login(result)
  }

  async login(provider: AuthProvider) {
    this.state.isProcessing = true
    this.state.isAuthorized = await this.authorize(provider)
    this.state.isProcessing = false
  }

  private authorize(provider: AuthProvider) {
    const session = new AuthSession()

    const result = session.promise.then(
      // Wait for token
      async (payload) => {
        Logger.debug('🔐 Result received from authorizer. Requesting verification from server...')

        const result = await api.auth.login(provider, {
          token: payload,
          auth_code: payload,
          ref: this.params.refCode
        })

        return AuthService.loginWithResult(result)
      },

      // Handle errors
      (error) => {
        if (error === 'window_closed_by_user') {
          Logger.debug('🔐 Authorization window closed by user')
          return false
        }

        throw error
      }
    )

    // Open authorization window
    this.launchAuthWindow(provider, session)
      // ? Token should be resolved before window is closed. Otherwise the user closed it.
      .finally(() => session.promise.reject('window_closed_by_user'))

    return result
  }

  private launchAuthWindow(provider: AuthProvider, session: AuthSession) {
    const url = session.createURL(provider)
    return openPopup(url.href, 400, 600, !!window.Office)
  }
}

export namespace AuthService {
  export interface Params {
    /** Account email hint */
    hint?: string

    /** Redirect to url after authorization */
    redirectUrl?: string

    /** Invite code */
    refCode?: string
  }
}
